import React from 'react'
import '../assets/css/Product.styl'
function Product() {
  return (
    <div className="product">

      <div className="flex-justify-center box">
          <div className="flex-column content  flex-justify-center">
              <img src={require("../assets/images/logo20.png")} alt="" width="118" height="118"/>
              <div className="fs-40 mt-24">
              轻蜜睡眠(快应用)
              </div>
              <div className="fs-20 mt-24 bold-200">
              无论是繁忙的白天还是夜晚难以入眠，轻蜜睡眠将成为你放松身心的最佳伴侣。
              </div>
              <a href="https://bj-zlb.oss-cn-beijing.aliyuncs.com/static_html/qmsm_q/app.rpk" target="_blank">
                  <div className="download-btn">
                      下载应用
                  </div>
              </a>
              <a href="qmsm_q/index.html" target="_blank">
                  <div className="detail-btn">
                      查看更多截图
                  </div>
              </a>
          </div>

          <div>
              <img src={require("../assets/images/phone_20.png")} alt="" width="324" height="514"/>
          </div>
      </div>


      <div className="flex-justify-center box">
        <div>
          <img
            // @ts-ignore
            src={require('../assets/images/phone_18.png')}
            alt=""
            width="324"
            height="514"
          />
        </div>
        <div className="flex-column content-right  flex-justify-center">
          <img
            // @ts-ignore
            src={require('../assets/images/logo18.png')}
            alt=""
            width="118"
            height="118"
          />
          <div className="fs-40 mt-24">金手指手机软件(快应用)</div>
          <div className="fs-20 mt-24 bold-200 ">
          无论是日常开销、收入还是长期投资，金手指手机软件都能帮助你清晰记录。
          </div>
          <a href="https://bj-zlb.oss-cn-beijing.aliyuncs.com/static_html/jszsjrj_q/app.rpk" target="_blank">
            <div className="download-btn">
              下载应用
            </div>
          </a>
          <a href="jszsjrj_q/index.html" target="_blank">
                            <div className="detail-btn">
                                查看更多截图
                            </div>
                        </a>
        </div>
      </div>

      <div className="flex-justify-center box">
          <div className="flex-column content  flex-justify-center">
              <img src={require("../assets/images/logo19.png")} alt="" width="118" height="118"/>
              <div className="fs-40 mt-24">
              奇力充电伴侣(快应用)
              </div>
              <div className="fs-20 mt-24 bold-200">
              不仅提供全国充电桩信息，让你找到便捷的充电站点，还汇聚了详尽的天气数据，确保你的充电计划无惧气象变化。
              </div>
              <a href="https://bj-zlb.oss-cn-beijing.aliyuncs.com/static_html/qlcdbl_q/app.rpk" target="_blank">
                  <div className="download-btn">
                      下载应用
                  </div>
              </a>
              <a href="qlcdbl_q/index.html" target="_blank">
                  <div className="detail-btn">
                      查看更多截图
                  </div>
              </a>
          </div>

          <div>
              <img src={require("../assets/images/phone_19.png")} alt="" width="324" height="514"/>
          </div>
      </div>


      <div className="flex-justify-center box">
        <div>
          <img
            // @ts-ignore
            src={require('../assets/images/phone_10.png')}
            alt=""
            width="324"
            height="514"
          />
        </div>
        <div className="flex-column content-right  flex-justify-center">
          <img
            // @ts-ignore
            src={require('../assets/images/logo10.png')}
            alt=""
            width="118"
            height="118"
          />
          <div className="fs-40 mt-24">极速上网管家</div>
          <div className="fs-20 mt-24 bold-200 ">
          你手机的网络安全专家，每天都保护您的上网安全，为你手机的安全快速运行保驾护航！
          </div>
          <a href="https://bj-zlb.oss-cn-beijing.aliyuncs.com/static_html/jsswgj_new/app.apk" target="_blank">
            <div className="download-btn">
              下载应用
            </div>
          </a>
          <a href="jsswgj/index.html" target="_blank">
                            <div className="detail-btn">
                                查看更多截图
                            </div>
                        </a>
        </div>
      </div>

    </div>
  )
}
export default Product
